
.modal-container {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
}

.modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  padding: 0;
  max-height: 80%;
  margin: auto;
  overflow: hidden;

  animation-name: fadeInUp;
  animation-duration: .1s;
}

.lean-overlay {
  position: fixed;
  z-index: 999;
  top: -100px;
  left: 0;
  bottom: 0;
  right: 0;
  height: 125%;
  width: 100%;
  background: #FFFFFF;
  display: block;
  opacity: 0.8;

  will-change: opacity;
}

.close {
  color: #808085;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  right: 15px;
  z-index: 1;
}

.close:hover,
.close:focus {
  color: #A2A2A2;
  text-decoration: none;
  cursor: pointer;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}


@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: .5s;
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: .3s;
}

.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: .3s;
}
