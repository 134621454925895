@import "../../scss/utils.scss";

.Screen-left-half, .Screen-right-half {
  position: absolute;
  width: 50%;
  height: 100%;

  @include sm {
    width: 100%;
  }
}

.Screen-right-half {
  right: 0;
}

.Screen-left-half {
  left: 0;
}

.paths-white {
  background: url(/images/home/paths-white.svg) repeat;
}

.paths-grey {
  background: url(/images/home/paths-grey.svg) repeat;
}

//.paths-green {
//  background: url(/images/home/paths-green.svg) repeat;
//}
//
//.paths-dark {
//  background: url(/images/home/paths-dark.svg) repeat;
//}
