@import "../../scss/utils";

.Container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  word-wrap: break-word;
  box-sizing: border-box;
  position: relative;

  @include sm {
    //  max-width: 540px;
    padding: 0 40px;
  }

  @include md {
    //  max-width: 720px;
    padding: 0 60px;
  }

  @include lg {
    //  max-width: 968px;
    padding: 0 60px;
  }

  @include xxl {
    max-width: 1320px;
    padding: 0;
  }
}

.Container-fluid {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.Container-clipped {
  overflow: hidden;
}

.Container-flex {
  display: flex;
  flex: 1;
}
