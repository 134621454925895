@import "../../scss/utils";

.Banner {
  display: flex;

  @include sm {
    flex-direction: column-reverse;
  }
}

.Banner-start {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.Banner-end {
  flex: 3;
  display: flex;
  justify-content: flex-end;

  @include sm {
    flex: 1;
  }
}

.Banner-title {
  font-size: 40px;
  line-height: 52px;
  display: flex;
  align-items: flex-end;

  @include sm {
    padding: 24px 0;
  }

  @include md {
    font-size: 64px;
    line-height: 83px;
  }
}

.Banner-title--light {
  color: $light-steal;
}

.Banner-info, .Page-info {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: $grey;

  @include  md {
    font-size: 20px;
    line-height: 26px;
  }
}

.Banner-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}
