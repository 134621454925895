@import "../../scss/utils";

.Footer {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  color: $grey;
  z-index: 1;
  background: #ffffff;

  a {
    color: $grey;
    font-size: 14px;
  }
}

.Footer-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 35px;

  @include sm {
    flex-direction: column;
  }
}

.Footer-nav {
  font-size: 18px;
  line-height: 23px;
}

.Footer-nav-item {
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding: 6px 0;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: $mint;
  }
}

.Footer-subscribe {
  font-size: 18px;
  line-height: 23px;
  width: 40%;
  color: $grey;

  .Contact-form {
    min-width: auto;
  }

  @include sm {
    width: 100%;
    margin-top: 50px;

    input, .btn {
      margin-left: 0;
    }
  }
}

.Footer-subscribe-info {
  margin: 16px 10px 0;

  @include sm  {
    margin-left: 0;
    margin-right: 0;
  }
}

.Footer-bottom {
  padding: 38px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .Logo-icon {
    display: none;
  }

  @include sm {
    .Logo {
      display: block;
    }

    .Logo-icon {
      display: block;
    }
  }
}

.Divider {
  border: none;
  height: 1px;
  color: #353842; /* old IE */
  background-color: #353842; /* Modern Browsers */
}
